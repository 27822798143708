export const environment = {
    production: true,
    env: 'qa',
    noindex: true,
    googleApiKey: 'AIzaSyA5jCsBXGkIaLtcmEY0ukgrZusDF7H_O04',
    siteDNSName: 'https://qa.staplesconnect.com',
    siteDNSNameStaples: 'https://qe1.staples.com',
    ideasRootUrl: 'https://qa.staplesconnect.com',
    eventsRootUrl: 'https://qa.staplesconnect.com/api/event',
    storeBaseURL: 'https://qa.staplesconnect.com/api/store',
    couponBaseURL: 'https://qa.staplesconnect.com/api/coupon',
    loyaltyBaseURL: 'https://qa.staplesconnect.com/api/loyalty/dashboard',
    cmsApiUrls: {
        live: {
            scheme: 'https',
            hostname: 'qa.staplesconnect.com',
            port: 443
        },
        preview: {
            scheme: 'https',
            hostname: 'sc-cms-qa.staplesconnect.com',
            port: 443
        }
    },
    cmsBaseUrlExternal: 'https://qa.staplesconnect.com/site',
    cmsBaseUrlSSR: 'https://sc-cms-qa.staplesconnect.com/site',
    cmsOrigin: 'https://sc-cms-qa.staplesconnect.com',
    olcURL: 'https://platformdev.liquidus.net/slplatform.js',
    officeRedoRegistrationURL: 'https://stapleshomeofficemakeover2021.staging.dja.com/',
    officeRedoGalleryURL: 'https://stapleshomeofficemakeover2021.staging.dja.com/gallery_view',
    officeRedoScriptURL:
        'https://stapleshomeofficemakeover2021.staging.dja.com/assets/js/iframe.js',
    thankATeacherRegistrationUrl: 'https://staplesthankteacher2022.staging.dja.com/',
    thankATeacherRegistrationScriptUrl:
        'https://staplesthankteacher2022.staging.dja.com/assets/js/iframe.js',
    thankATeacherGalleryScriptUrl:
        'https://staplesthankteacher2022.staging.dja.com/assets/js/iframe_gallery.js',
    thankATeacherGalleryUrl: 'https://staplesthankteacher2022.staging.dja.com/gallery/',
    tsaIPUrl:
        'https://outlook.office365.com/book/QAStaplesServices0348@ussicorp5.onmicrosoft.com/',
    recaptchaApiKey: '6Lfq3UcbAAAAAAURhvsumc5d5ZRIBpWvYsRY5doN',
    wiseHer: 'https://app.dev.wiseher.com/?referrer=eb6e4123-a3e6-434f-9962-7684cd93ee04',
    printPossible: 'https://staples-inc.massrel.io/print-possible',
    loveMyStaplesPrints: 'https://staples-inc.massrel.io/lovemystaplesprints',
    findYourWfhStyle: 'https://stapleswfhstyle2022.staging.dja.com/',
    findYourWfhStyleScriptUrl: 'https://stapleswfhstyle2022.staging.dja.com/assets/js/iframe.js',
    mobileDeepLink: 'https://staplesconnectqa.page.link/?link=https://qe-app.staplesconnect.com/coupons/<COUPON_NUMBER>&isi=1570298170&ibi=com.staplesconnect.retailmobile.StaplesConnectApp-QA&st=Staples+–+Deals+%26+Shopping&sd=Proceed+to+your+exclusive+deal!&si=https://www.staplesconnect.com/site/binaries/content/assets/staples-connect/mobile-app/deals_wide.png',
    appStoreLink: 'https://apps.apple.com/us/app/staples-deals-shopping/id1570643833?utm_source=staples.com&utm_medium=referral&utm_campaign=staples-coupons&utm_term=download+app',
    playStoreLink: 'https://play.google.com/store/apps/details?id=com.staplesconnect.app.twa&referrer=utm_source%3Dstaples.com%26utm_medium%3Dreferral%26utm_term%3Ddownload%2520app%26utm_campaign%3Dstaples-coupons%26anid%3Dadmob',
    baseUrl: undefined,
    endpoint: undefined,
    auth: {
        sendTokenRoutes: ['https://qa.staplesconnect.com/api'],
        redirectUrl: 'https://qa.staplesconnect.com/token',
        postLogoutRedirectUri: 'https://qa.staplesconnect.com/',
        silentRefreshUri: 'https://qa.staplesconnect.com/silent-refresh.html',
        logoutURL:
            'https://login-dev.staplesconnect.com/ec99d7d1-3a3c-4c92-a69c-e54650678ea1/auth-ui/logout?client_id=55b111a1-953f-4875-a623-bf14908eb642',
        clientId: '55b111a1-953f-4875-a623-bf14908eb642',
        loginURL: 'https://login-dev.staplesconnect.com/ec99d7d1-3a3c-4c92-a69c-e54650678ea1/login'
    },
    dotcom: {
        clientId: 'ad1921d711ae4348b948885e29db91bc',
        APIMDomain: 'https://easyapiqa.staples.com'
    },
    recycleAPIEndpoint:
        'https://de-gateway.staplesconnect.com/external/qe/recycleapi/v1/storeinfo/',
    recycleAPIKey: 'bfca0ef497f9489e80d63d6dcbbaedd0',
    defaultStore: '0008',
    enableRedisCache: false
};
